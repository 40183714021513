// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-about-js": () => import("./../../../src/pages/components/about.js" /* webpackChunkName: "component---src-pages-components-about-js" */),
  "component---src-pages-components-book-section-js": () => import("./../../../src/pages/components/bookSection.js" /* webpackChunkName: "component---src-pages-components-book-section-js" */),
  "component---src-pages-components-download-page-content-js": () => import("./../../../src/pages/components/downloadPageContent.js" /* webpackChunkName: "component---src-pages-components-download-page-content-js" */),
  "component---src-pages-components-download-section-js": () => import("./../../../src/pages/components/downloadSection.js" /* webpackChunkName: "component---src-pages-components-download-section-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-head-js": () => import("./../../../src/pages/components/head.js" /* webpackChunkName: "component---src-pages-components-head-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-signup-form-js": () => import("./../../../src/pages/components/signupForm.js" /* webpackChunkName: "component---src-pages-components-signup-form-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

